
















import { Inject } from 'inversify-props';
import { Component, Vue } from 'vue-property-decorator';
import FleetService, { FleetServiceS } from '@/modules/cars/modules/fleet/fleet.service';
import FleetGraphBrokers from '@/modules/cars/modules/fleet/components/graph/fleet-graph-brokers.vue';
import FleetGraphRangeLabel from '@/modules/cars/modules/fleet/components/graph/fleet-graph-range-label.vue';
import GRAPH_TYPE from '@/modules/cars/modules/fleet/constants/graph-types.constant';
import FleetGraphBrokersLabel from '@/modules/cars/modules/fleet/components/graph/fleet-graph-brokers-label.vue';
import FleetGraphHeader from './fleet-graph-header.vue';
import FleetGraphRange from './fleet-graph-range.vue';
import FleetFilterService, { FleetFilterServiceS } from '../../fleet-filter.service';

@Component({
    components: {
        FleetGraphBrokersLabel,
        FleetGraphRange,
        FleetGraphBrokers,
        FleetGraphHeader,
        FleetGraphRangeLabel,
    },
})
export default class FleetGraphSet extends Vue {
    @Inject(FleetServiceS) private fleetService!: FleetService;
    @Inject(FleetFilterServiceS) private fleetFiltersService!: FleetFilterService;

    disabled: Record<string, string[]> = {};

    mounted() {
        this.fleetFiltersService.initCompetitorFilters();
    }

    get providers() {
        return this.fleetService.brokers;
    }

    isRange(provider: string) : boolean {
        return this.fleetService.getGraphType(provider) === GRAPH_TYPE.RANGE;
    }

    setProvidersDisabledCompetitors(provider: string, competitors: string[]) {
        this.disabled = { ...this.disabled, [provider]: competitors };
    }

    getProvidersDisabledCompetitors(provider: string) {
        return this.disabled[provider] || [];
    }
}
