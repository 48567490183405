













import {
    Component, Vue, Prop,
} from 'vue-property-decorator';
import Day from '@/modules/common/types/day.type';
import RatesDayScanBtn from '@/modules/rates/components/rates-day-scan-btn.vue';
import CarsDotDropdown from '@/modules/cars/components/cars-dot-dropdown.vue';

@Component({
    components: {
        CarsDotDropdown,
        RatesDayScanBtn,
    },
})
export default class FleetGraphTooltip extends Vue {
    @Prop({
        required: true,
        type: Number,
    })
    private day!: Day;

    @Prop({
        required: true,
        type: String,
    })
    private provider!: string;

    @Prop({
        required: false,
        type: Function,
    })
    private freezeTooltip!: Function;

    handleDotsClick(e: Event) {
        e.stopPropagation();
    }

    handleTooltipClick() {
        this.$emit('click', this.day);
    }

    get items() {
        return [
            {
                link: `day-popup/${this.day}/${this.provider}`,
                text: 'Details',
            },
        ];
    }
}
