












import { Vue, Component, Prop } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import CustomSwitch from '@/modules/common/components/ui-kit/custom-switch.vue';
import GRAPH_TYPE from '@/modules/cars/modules/fleet/constants/graph-types.constant';
import FleetService, { FleetServiceS } from '@/modules/cars/modules/fleet/fleet.service';

@Component({
    components: {
        CustomSwitch,
    },
})
export default class FleetGraphTypeChanger extends Vue {
    @Inject(FleetServiceS) private fleetService!: FleetService;

    @Prop({
        type: String,
        required: true,
    })
    provider!: string;

    get value(): boolean {
        return this.fleetService.getGraphType(this.provider) === GRAPH_TYPE.BROKERS;
    }
}
